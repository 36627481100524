<script lang="ts" setup>
import { VContainer, VRow, VCol, VCard, VBtn, VIcon } from 'vuetify/components';
import DeliverableTable, {
    type DeliverableTableItem,
} from '@/components/DeliverableTable.vue';
import { useQuery } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { computed } from 'vue';
import { useRouteParams } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import DeliverableCreateModal from './DeliverableCreateModal.vue';
import PhaseCreateModal from './PhaseCreateModal.vue';
import { usePermissions } from '@/composables/permissions';
import { PolicyAction } from '@/types';
import type { Project } from '@/gql/graphql';

const { t } = useI18n({
    messages: {
        nl: {
            title: '@:deliverables',
            create_phase: 'Fase aanmaken',
            create_deliverable: '@:deliverable aanmaken',
        },

        en: {
            title: '@:deliverables',
            create_phase: 'Create phase',
            create_deliverable: 'Create @.lower:deliverables',
        },
    },
});

const { hasPermission } = usePermissions();

const projectId = useRouteParams<string>('id');

const variables = computed(() => ({
    filters: [
        {
            field: 'id',
            value: projectId.value,
        },
    ],
}));

const { result, loading, error, refetch } = useQuery(
    graphql(`
        query ProjectPhases($filters: [FilterCriterion]) {
            projects(filters: $filters) {
                items {
                    id
                    masterPortfolioId
                    parentProjectId
                    deliverables {
                        id
                        name
                        status
                        state
                        milestone
                        startDate
                        endDate
                        phase {
                            id
                        }
                        project {
                            id
                            parentProjectId
                            masterPortfolioId
                        }
                    }
                    phases {
                        id
                        name
                        description
                        startDate
                        endDate
                        deliverables {
                            id
                        }
                    }
                }
            }
        }
    `),
    variables,
    { fetchPolicy: 'cache-first' }
);

const project = computed<Project>(() => result.value?.projects?.items?.[0]);

const canUpdateProject = computed(() => {
    return (
        project.value &&
        (hasPermission(
            PolicyAction.PROJECT_WRITE,
            project.value.id,
            project.value.masterPortfolio?.id
        ) ||
            hasPermission(
                PolicyAction.PROJECT_WRITE,
                project?.value.parentProjectId ?? undefined,
                project?.value.masterPortfolio?.id ?? undefined
            ))
    );
});
const canEscalateDeliverable = computed(() => {
    return (
        project.value &&
        hasPermission(
            PolicyAction.ESCALATION_CREATE,
            project.value.id,
            project.value.masterPortfolioId ?? undefined
        )
    );
});
const phases = computed<DeliverableTableItem[]>(() => {
    type PhaseResult = NonNullable<typeof phases>[0];
    const phases = project.value?.phases;

    if (!phases?.length) return [];

    function deliverablesInPhase(phase: PhaseResult) {
        return (
            project.value?.deliverables
                ?.filter((deliverable) => deliverable.phase?.id === phase.id)
                .map((deliverable) => {
                    return {
                        id: deliverable.id,
                        name: deliverable.name,
                        type: {
                            isMilestone: deliverable.milestone,
                            status: deliverable.status,
                        },
                        state: deliverable.state,
                        startDate: deliverable.startDate ?? undefined,
                        endDate: deliverable.endDate,
                        project: {
                            id: deliverable.project.id,
                            masterPortfolioId:
                                deliverable.project.masterPortfolioId,
                            parentProjectId:
                                deliverable.project.parentProjectId,
                        },
                    };
                }) || []
        );
    }

    return phases.map((phase) => ({
        name: phase.name,
        id: phase.id,
        ...(phase.description && { description: phase.description }),
        ...(phase.startDate && { startDate: phase.startDate }),
        endDate: phase.endDate,
        deliverables: deliverablesInPhase(phase),
        project: {
            id: project.value?.id,
            masterPortfolioId: project.value?.masterPortfolioId,
            parentProjectId: project.value?.parentProjectId,
        },
    }));
});

const unphasedDeliverables = computed<DeliverableTableItem[]>(() => {
    return (
        project.value?.deliverables
            ?.filter((deliverable) => !deliverable.phase)
            .map((deliverable) => {
                return {
                    id: deliverable.id,
                    name: deliverable.name,
                    type: {
                        isMilestone: deliverable.milestone,
                        status: deliverable.status,
                    },
                    state: deliverable.state,
                    startDate: deliverable.startDate ?? undefined,
                    endDate: deliverable.endDate,
                    project: {
                        id: deliverable.project.id,
                        masterPortfolioId:
                            deliverable.project.masterPortfolioId,
                        parentProjectId:
                            deliverable.project.parentProjectId,
                    },
                };
            }) ?? []
    );
});
</script>

<template>
    <v-container>
        <v-row>
            <v-col md="6" class="d-flex align-center">
                <h1
                    class="text-title-large text-primary-30 d-flex align-center ga-2"
                >
                    <v-icon class="text-primary-20">$escalation</v-icon>
                    {{ t('title') }}
                </h1>
            </v-col>
            <v-col md="6" align="right" class="d-flex ga-3 justify-end">
                <PhaseCreateModal v-if="canUpdateProject" :project-id>
                    <template #activator="{ props }">
                        <v-btn
                            variant="outlined"
                            prepend-icon="$add"
                            v-bind="props"
                            >{{ t('create_phase') }}</v-btn
                        >
                    </template>
                </PhaseCreateModal>

                <DeliverableCreateModal v-if="canUpdateProject">
                    <template #activator="{ props }">
                        <v-btn
                            variant="outlined"
                            prepend-icon="$add"
                            :disabled="loading"
                            v-bind="props"
                        >
                            {{ t('create_deliverable') }}
                        </v-btn>
                    </template>
                </DeliverableCreateModal>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="pa-8">
                    <p v-if="error" class="text-error">{{ error }}</p>
                    <DeliverableTable
                        :items="[...unphasedDeliverables, ...phases]"
                        :can-update="canUpdateProject"
                        :can-escalate="canEscalateDeliverable"
                        :loading
                        @phase-deleted="refetch"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
