type EntryName =
    | 'deliverable'
    | 'phase'
    | 'project'
    | 'program'
    | 'theme'
    | 'idea'
    | 'organisation'
    | 'portfolio'
    | 'department'
    | 'tag'
    | 'user'
    | 'document'
    | 'task'
    | 'bottleneck'
    | 'highlight'
    | 'preview'
    | 'contact';

type FieldRequirements = {
    min?: number;
    max?: number;
};

type Fields = {
    [key: string]: FieldRequirements;
};

type Config = {
    [key in EntryName]?: Fields;
};

export const requirements = {
    deliverable: {
        name: {
            min: 1,
            max: 100,
        },
        description: {
            min: 1,
            max: 500,
        },
    },
    phase: {
        name: {
            min: 1,
            max: 100,
        },
        description: {
            min: 1,
            max: 500,
        },
    },
    project: {
        abbreviation: {
            min: 1,
            max: 10,
        },
        name: {
            min: 1,
            max: 150,
        },
        description: {
            max: 700,
        },
        goal: {
            max: 700,
        },
        scope: {
            max: 700,
        },
        result: {
            max: 700,
        },
        risks: {
            max: 700,
        },
        stakeholders: {
            max: 700,
        },
    },
    program: {
        number: {
            min: 1,
            max: 6,
        },
        name: {
            min: 1,
            max: 50,
        },
        description: {
            min: 1,
            max: 700,
        },
        goal: {
            min: 1,
            max: 700,
        },
    },
    department: {
        name: {
            min: 1,
            max: 50,
        },
    },
    theme: {
        name: {
            min: 1,
            max: 50,
        },
    },
    organisation: {
        name: {
            min: 1,
            max: 255,
        },
    },
    user: {
        firstName: {
            min: 1,
            max: 255,
        },
        lastName: {
            min: 1,
            max: 255,
        },
        email: {
            min: 1,
            max: 255,
        },
    },
    tag: {
        name: {
            min: 1,
            max: 50,
        },
        portfolioId: {
            min: 1,
        },
    },
    document: {
        name: {
            min: 1,
            max: 50,
        },
        description: {
            min: 1,
            max: 250,
        },
    },
    task: {
        name: {
            min: 1,
            max: 100,
        },
    },
    bottleneck: {
        name: {
            min: 8,
            max: 50,
        },
        description: {
            min: 1,
            max: 250,
        },
        responsible: {
            min: 1,
            max: 255,
        },
        solution: {
            min: 8,
            max: 250,
        },
        escalationReason: {
            min: 8,
            max: 250,
        },
    },
    idea: {},
    portfolio: {
        name: {
            min: 1,
            max: 100,
        },
    },
    highlight: {
        name: {
            min: 1,
            max: 100,
        },
        description: {
            min: 1,
            max: 200,
        },
    },
    preview: {
        name: {
            min: 1,
            max: 100,
        },
        description: {
            min: 1,
            max: 200,
        },
    },
    contact: {
        question: {
            min: 1,
            max: 250,
        },
    },
} satisfies Config;

export function getRequirements(entry: EntryName, field?: string) {
    const r = requirements[entry];

    if (!r) return null;
    if (!field) return r;

    return r[field];
}
