import { useQuery } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { computed, ref, toValue, type MaybeRefOrGetter } from 'vue';
import {
    Direction,
    type FilterCriterion,
    type SortingCriterion,
} from '@/gql/graphql';
import { useUserSettingsStore } from '@/stores/user-settings';
import { isNotNull } from '@/utils';
import { provide } from 'vue';
import { STARRED_PORTFOLIOS } from '@/utils/cascading';
import type { Portfolio } from '@/types';
import { useNotificationStore } from '@/stores/notification';
import { useI18n } from 'vue-i18n';

export function useSelectablePortfolios(
    organisationId?: MaybeRefOrGetter<string | undefined>
) {
    const userSettingsStore = useUserSettingsStore();

    const accountId = computed(() => {
        return (
            toValue(organisationId) ??
            userSettingsStore.activeOrganisation?.id ??
            ''
        );
    });

    const variables = computed<{
        sorting: SortingCriterion[];
        filters: FilterCriterion[];
    }>(() => ({
        sorting: [{ field: 'name', direction: Direction.ASC }],
        filters: [{ field: 'account_id', value: accountId.value }],
    }));

    const { result, ...rest } = useQuery(
        graphql(`
            query SelectablePortfolios(
                $filters: [FilterCriterion]
                $sorting: [SortingCriterion]
            ) {
                portfolios(filters: $filters, sorting: $sorting) {
                    items {
                        id
                        name
                    }
                }
            }
        `),
        variables
    );

    const portfolios = computed(() => result.value?.portfolios?.items ?? []);

    return { portfolios, ...rest };
}

export interface NodePortfolio
    extends Pick<Portfolio, 'id' | 'name' | 'parentId'> {
    hasAccess: boolean;
    ghost: boolean;
}

export function useCascadingPortfolios() {
    const { t } = useI18n({
        messages: {
            nl: {
                error_loading_portfolios:
                    'Ophalen @.lower:portfolios niet gelukt. Probeer later opnieuw',
            },
            en: {
                error_loading_portfolios:
                    "Unable to get @.lower:{'portfolios'}, try again later",
            },
        },
    });

    const userSettingsStore = useUserSettingsStore();

    const variables = computed(() => ({
        organisationId: userSettingsStore.activeOrganisation?.id || '',
    }));
    // TODO: Add error handling when graphql spec up to date
    const { onResult: onPortfolioListResult, loading } = useQuery(
        graphql(`
            query PortfoliosCascading($organisationId: ID!) {
                allPortfolios(accountId: $organisationId) {
                    items
                }
                currentUser {
                    starredPortfolios {
                        id
                    }
                }
            }
        `),
        variables,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const notificationStore = useNotificationStore();

    const portfolios = ref<NodePortfolio[]>([]);

    const starredPortfolios = ref<string[]>([]);
    provide(STARRED_PORTFOLIOS, starredPortfolios);

    onPortfolioListResult(async (response) => {
        // TODO: Remove this error handler when API is on spec and use the above onPortfolioListError.
        if (
            response.error ||
            response.errors ||
            !response.data.allPortfolios?.items
        ) {
            notificationStore.add({
                type: 'error',
                message: t('error_loading_portfolios'),
            });
            return;
        }
        const { items: _portfolios } = response.data.allPortfolios;

        _portfolios.forEach((portfolio) => delete portfolio?.__typename);
        const filtered = _portfolios.filter(
            (i) => i !== null
        ) as NodePortfolio[];

        starredPortfolios.value =
            response.data.currentUser?.starredPortfolios
                ?.filter(isNotNull)
                .map((p) => p?.id) ?? [];

        portfolios.value = filtered;
    });

    return { portfolios, loading };
}
