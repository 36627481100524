import type { NodePortfolio } from '@/composables/portfolios';
import type { Edge, Elements, Node, XYPosition } from '@vue-flow/core';
import { Position } from '@vue-flow/core';

export function portfoliosToNodes(portfolioList: NodePortfolio[]): Elements {
    const position: XYPosition = { x: 0, y: 0 };
    const targetPosition: Position = Position.Left;
    const sourcePosition: Position = Position.Right;

    const elements: Elements = [];

    // Add the nodes
    const nodes: Elements = portfolioList.map((item) => {
        return {
            label: item.name,
            id: item.id,
            data: {
                hasAccess: item.hasAccess,
                ghost: item.ghost,
                parent: item.ghost ? item.parentId?.toString() : undefined,
            },
            selectable: item.hasAccess,
            position,
            targetPosition,
            sourcePosition,
            draggable: false,
            type: item.ghost ? 'ghost' : 'custom',
        } satisfies Node;
    });
    // Create the lines (edges) connecting the nodes
    portfolioList.forEach((item) => {
        if (item.parentId) {
            elements.push({
                type: 'edge',
                animated: item.ghost,
                style: item.ghost
                    ? { stroke: 'rgb(var(--v-theme-neutral-variant-60))' }
                    : undefined,
                id: `${item.id}${item.parentId}`,
                target: item.id,
                source: item.parentId.toString(),
                selectable: false,
            } satisfies Edge);
        }
    });

    elements.push(...nodes);

    return elements;
}
